import IMask from 'imask'
import Swiper from 'swiper';
import SwiperCore, { Pagination } from 'swiper/core';
import ScrollTo from './scrollTo'
import 'whatwg-fetch'

import 'swiper/swiper-bundle.css';
import '../scss/style.scss';



const numbers = document.querySelectorAll('.section-1__item_num[data-num]');
if (numbers){
  numbers.forEach(el => {
    let speed = 70;
    let curr = 0;
    let num = +el.dataset.num;
    let step = +el.dataset.step;
    setInterval(() => {
      if (curr < num) {
        curr+=step;
        el.innerHTML = curr + '%'; 
      } 
    }, speed);
  })
}

const openPopup = document.querySelectorAll('.open_popup');
const popUp = document.querySelector('.section-popup');
const popUpClose = document.querySelectorAll('.popup__close');

if (openPopup) {
  openPopup.forEach(el => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      popUp.classList.add('section-popup--active');
      document.querySelector(el.getAttribute('href')).classList.add('popup-active');
      document.documentElement.style.overflow = 'hidden';
    })
  });
}

if (popUp || popUpClose) {
  const closePopUp = () => {
    popUp.classList.remove('section-popup--active');
    document.querySelector('.popup-active').classList.remove('popup-active');
    document.documentElement.style.overflow = 'auto';
  }
  popUp.addEventListener('click', (e)=> {
    if(e.target.classList.contains('section-popup'))
      closePopUp()
  });
  popUpClose.forEach(el => el.addEventListener('click', (e)=> closePopUp()));
}

let thresholdSet = [];

for (let i=  0; i <= .8; i += 0.01) {
	thresholdSet.push(i);
}

let section5digital = document.querySelector('.section-5__digital');
if (section5digital){
  let section5generation = document.querySelector('.section-5__generation');
  let observerBar = new IntersectionObserver((entries)=>
  entries.forEach(entry => {
    let ratio = entry.intersectionRatio;
    section5digital.style.transform = `translate(-${ratio*3}em, ${ratio*10}em) scale(${1-ratio/3})`;
    section5generation.style.transform = `translate(${ratio*2.5}em, ${ratio*6}em)`;
  }), {threshold: thresholdSet});
  observerBar.observe(document.querySelector('.section-5'));
}

let breakfast5DG = document.querySelector('.breakfast-5__dg');
let breakfast5 = document.querySelector('.breakfast-5');
let breakfast5Height = null;// breakfast5.offsetHeight;
if (breakfast5){
  document.addEventListener('scroll', (e)=>{
    if( breakfast5.getBoundingClientRect().top < 0 && breakfast5.getBoundingClientRect().bottom > 0){
    }
  });
  let breakfast5generation = document.querySelector('.breakfast-5__digital_generation');
  let observerBar = new IntersectionObserver((entries)=>
  entries.forEach(entry => {
    let ratio = entry.intersectionRatio;
    breakfast5DG.style.transform = `translateX(-${ratio*8}em)`;
    breakfast5generation.style.transform = `translateY(-${ratio*5}em)`;
  }), {threshold: thresholdSet});
  observerBar.observe(document.querySelector('.breakfast-5'));
}

SwiperCore.use([Pagination]);


let swiper = [];
let breakPoint = 768;

const sliderInit = (swiperArr) => {
  swiperArr.forEach((el, i) => swiper[i] = new Swiper(`${el}__items`, {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },
    }
    })
  );
}
if (document.querySelector('.section-9')){
  sliderInit(['.section-9']);
} else if (document.querySelector('.breakfast-6')) {
  sliderInit(['.breakfast-6']);
}


// Маска для телефона
const phoneInputs = document.querySelectorAll('input[type=tel]');
phoneInputs.forEach(el => {
  IMask(el, {
    mask: '+{7}(000)000-00-00',
    lazy: false,
  });
})


let sendBtns = document.querySelectorAll('.send');
let forms = document.querySelectorAll('.form');

const checkEmail = email => (/^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i).test(email);
const checkPhone = phone => (/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/).test(phone);

if (sendBtns){

  sendBtns.forEach(el => {
    el.addEventListener('click', (e)=>{
      e.preventDefault();
      let check = true;
      let inputs = forms[+el.dataset.form-1].querySelectorAll('.input');
      let name="", email="", phone="", link="";
      inputs.forEach(el => {
        if (el.value == "" || (el.type == 'tel' && !checkPhone(el.value)) || (el.type == 'email' && !checkEmail(el.value))){
          check = false;
          el.classList.add('input-error');
        } else {
          el.classList.remove('input-error');
          if (el.type == 'tel') phone = el.value;
          if (el.type == 'email') email = el.value;
          if (el.classList.contains('input-link')) link = el.value;
          if (el.type == 'text' && !el.classList.contains('input-link')) name = el.value;
        }
      });

      if(check){
        inputs.forEach(el => el.value = "");
        let response = window.fetch('mail.php', {
          method: 'POST',
          body: JSON.stringify({name: name, email: email, phone: phone, link: link}),
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
        }).then(response => response.json())
        .then( result => {
          if(result.res){
            if (!popUp.classList.contains('section-popup--active')){
              popUp.classList.add('section-popup--active');
              document.documentElement.style.overflow = 'auto';
            }
            if(document.querySelector('.popup-active')){
              document.querySelector('.popup-active').classList.remove('popup-active');
            }
            document.querySelector('#thanks').classList.add('popup-active');
            } else {
              alert('К сожалению, что-то пошло не так')
            }
          });
      }


    });
  });
}